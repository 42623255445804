/* scafolding */
/* scafolding */
.at-container {
	border: 1px solid #666;
	border-radius: 5px;
	box-shadow: 2px black bottom;
	margin-bottom: 100px;
}

.at-mainCol {
	padding-top: 20px;
	padding-bottom: 30px;
}

.at-header-left-logo img {
	margin-top: 10px;
	width: 200px;
	height: auto
}

.at-header-left-title {
	padding-left: 0px
}

.at-header-left-title h4 {
	text-transform: uppercase;
	margin-bottom: 3px;
}

.at-header-left-title h6 {
	color: #666;
}

/* headers */
/* headers */
.at-header {
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 10px;
	min-height: 75px;
	margin-bottom: 30px;
}

.at-header h5 {
	margin-bottom: 0px;
	text-transform: uppercase;
}

.at-user p {
	margin-bottom: 0px;
}

.at-user span {
	font-weight: 700;
}

.at-user small {
	color: #666;
}

.at-guest {
	font-size: 14px;
}

.at-warning {
	position: relative;
	bottom: 1px;
}

/* AtencionesList */
.at-atencionLoop {
	margin-bottom: 10px;
	width: 100%;
}

.at-atencionLoop button {
	margin-right: 10px;
	padding: 2px 10px;
	font-size: 12px;
}

.at-atencionLoop button svg {
	margin-right: 5px;
	position: relative;
	bottom: 2px;
}

.at-atencionLoop .card-body {
	padding: 10px 15px 5px 15px;
}

/* vista atencion */
.va-left {
	border-right: 1px solid #e8e8e8;
}

/* form */
/* form */
.with-error {
	border-color: red !important;
}
.form-hint {
	font-size: 14px;
	padding-bottom: 0px !important;
	margin-bottom: 20px;
}
.at-form label {
	font-size: 14px;
	padding-left: 0px;
	cursor: hand;
	cursor: pointer;
}

.at-form .form-group {
	margin-bottom: 3px;
}

.alert.small {
	margin-top: 5px;
	padding: 2px 10px;
}

input.with-error {
	border-color: #dc3545;
}

/* catalogo */
/* catalogo */
.at-catloop {
	/* margin-right:5px; */
	padding: 10px;
	height: 100px;
	border-radius: 3px;
	font-size: 15px;
	vertical-align: middle;
	cursor: hand;
	cursor: pointer;
}

.selectedCatLoop {
	/* border-color: red !important; */
	background-color: #e8e8e8 !important;
}

.at-catloop:hover {
	border-color: #666;
}

/* responsabilidades */
.at-newRes button {
	margin-top: 10px;
	margin-right: 10px;
}

.at-res-list-table tr td p {
	margin-bottom: 0px;
}

.at-inline button {
	display: inline-block !important;
	margin: 0px;
	margin-right: 10px;
}

.at-user {
	text-align: right;
}

.va-right {
	padding-left: 20px !important;
	padding-bottom: 20px !important;
}

.va-content {
	padding: 0px 30px !important;
	padding-bottom: 30px !important;
}

.container-fluid {
	padding-left: 50px;
	padding-right: 50px;
}

.at-loop-area {
	float: right;
	margin-top: 3px;
}


/* .at-loop-actions h4 {
	float: right !important;
} */

@media screen and (max-width: 1300px) {
	.container-fluid {
		padding-left: 30px;
		padding-right: 30px;
	}

	/* .at-loop-actions h4 {
		margin-top: 15px;
		float: left !important;
	} */
}



/* responsive */
@media screen and (max-width: 767px) {
	.at-header {
		text-align: center;
	}

	.at-header h6 {
		margin-bottom: 20px;
	}

	.at-user {
		text-align: center !important;
	}

	.va-left {
		border-right: 0px !important;
		padding-bottom: 20px;
	}

	.va-left {
		padding: 0px 20px !important;
		padding-bottom: 30px !important;
	}

	.va-right {
		padding: 0px 20px !important;
		padding-bottom: 30px !important;
		border-bottom: 1px solid #e8e8e8;
		margin-bottom: 20px;
	}

	.va-content {
		padding: 0px 20px !important;
		padding-bottom: 30px !important;
	}

	.va-tutorial {
		padding: 5px 20px !important;
		padding-bottom: 30px !important;
	}

	/* .at-loop-actions h4 {
		margin-top: 30px;
		float: right !important;
	} */

	.at-inline button {
		margin-bottom: 10px;
	}
}

/* marker */
.maper-marker {
	position: absolute;
	width: 50px;
	height: 50px;
	/* background-color:blue; */
	z-index: 9000;
	color:red;
	font-size:30px
}

/* marker */
.text-hint {
	color:#666;
}

.form-atencion-container h4 {
	padding-bottom: 15px;
}

.at-list-hint {
	font-size:13px !important;
}
.at-loop-area-badge {
	max-width:100%;
	overflow: hidden;
	text-overflow: ellipsis;
}
.at-tiempos-card {
	text-decoration: none !important;
	color:#333 !important;
}
.at-tiempos-card:hover {
	border-color:#333 !important;
}
a.at-tiempos-link:hover {
	text-decoration: none !important;
}
.at-tiempos-card {
	padding:30px;
	width:100%;
	text-align: center;
}
.at-tiempos-card svg {
	margin:0px auto;
	margin-bottom: 20px;
}

.at-tiempos-card .badge {
	width:50% !important
}